.carousel-indicators {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0;
    justify-content: center;
  }

  .carousel-control-next {
    
    
  }