.FormInputsContactenos{
    margin-left: auto; 
    margin-right:auto; 
    width: 364px !important; 
    height: 52px;
    max-width:88vw; 
    border-radius: 50px !important;
    background-color:#f1f3f5 !important;
    border: none !important;
    box-shadow:none !important;

    transition: transform 0.3s ease-in-out !important;
}

.FormTextareaContactenos{
    margin-left: auto; 
    margin-right:auto; 
    width: 364px !important; 
    max-width:88vw; 
    border-radius: 20px !important;
    background-color:#f1f3f5 !important;
    border: none !important;
    box-shadow:none !important;
    resize: none ;

    transition: transform 0.3s ease-in-out !important;
}

.FormInputsContactenos:focus , .FormTextareaContactenos:focus {
    transform: translateY(-3px);

}

.FormButtonSubmit {
    border-radius:0 !important;
    background:#46c3c7 !important;
    border: none !important;
    margin-top:35px !important;
    margin-bottom:40px !important;
    width:260px !important;
    height:54px !important;

    transition: transform 0.5s  ease-in-out !important;
}

.FormButtonSubmit:hover{
    background:#46c3c7c0 !important;
    border: none !important;
}

.FormButtonSubmit:focus{
    transform: translateY(5px);
}

.FormButtonSubmit:active{
    
    background:rgba(70, 195, 199, 0.486) !important;
    border: none !important;
    box-shadow: 0 0 0 0.2rem rgba(70, 195, 199, 0.774) !important;
}