.myHeader {
    /* Section 1 */


    box-sizing: border-box;

    position: relative;
    width: auto;
    height: 150px;

    background: #dd1111;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0px 2px 2px 200px;
}






