@import 'bootstrap/dist/css/bootstrap.min.css';


.btn-primar{
    color: white;
    background-color: #46c3c7;
}

    
.btn-primar:hover{
    background-color: #95b8d1;
    
}

.btn-primar--active{
    background-color: rgb(70 195 199 / 65%);
}

.btn-mision {
    color: white !important;
    border-radius:0 !important;
    border-color:#46c3c7 !important; 
    background-color:#46c3c7 !important; 
    width:250px !important;
}

.btn-mision:hover{
    color:white!important;
    border-color:#46c3c7 !important; 
    
    background-color: rgb(70 195 199 / 65%) !important;
}

.btn-mision--active{
    border-color:#46c3c7; 
    background-color: rgb(70 195 199 / 65%) !important;
} 


