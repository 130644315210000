.MyBackground {
    min-height: 90vh;

    background: #2980B9;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FFFFFF, #6DD5FA, #2980B9);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FFFFFF, #6DD5FA, #2980B9);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.multiline-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap;
    /* let the text wrap preserving spaces */
}



/*Probando css*/

.flip-card {
    background-color: transparent;
    width: 190px;
    height: 254px;
    perspective: 1500px;
    font-family: sans-serif;
}

.TitledText {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}





.flip-card-front {
    background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,
            rgb(255, 211, 195) 40%, rgba(255, 127, 80, 0.603) 48%);
    color: coral;
}

.flip-card-back {
    background: linear-gradient(120deg, rgb(255, 174, 145) 30%, coral 88%,
            bisque 40%, rgb(255, 185, 160) 78%);
    color: white;
    transform: rotateY(180deg);
}

.MyGlassmorphism {
    background: rgba(58, 58, 58, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}
/* mediumblue */