
.animated {
    
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    
    background-color: #198aa7;
    background-image: linear-gradient(to bottom, rgba(14, 65, 102, 0.86), #0ca79a);
    z-index: 1;
}

.complete {
    min-height: 100vh; /* set the height of the body to 100% so that the flex container can take up the full height of the screen */
  }

  .flex-container {
    display: flex;
    align-items: center; /* center the child elements vertically */
    justify-content: center; /* center the child elements horizontally */
    height: 100vh; /* set the height of the flexbox to 100% so that it takes up the full height of the screen */
  }
  
  .background {
    position: absolute; /* position the background element absolutely within the container */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* set a background color or background image for the background element */
    z-index: 1; /* set the z-index to be behind the content element */
  }
  
  .content {
    text-align: left; /* center the text horizontally */
    z-index: 2; /* set the z-index to be in front of the background element */
  }