@media (min-width: 1000px) {
    .PersonalizedHeader {
        /*background-color: #000000;*/ 
        color: #ffffff; 
        border-radius: 0px 2px 2px 200px;
    }
    
}

.PersonalizedHeader{
    padding: 10px;
    text-align: center;
    background-color: rgb(36, 36, 36);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.IconsPer{
    color: #ffffff !important;

}

.IconsPer:hover{
    color: #00f0ff !important;
}


.IconsWhatsApp:hover {
    /* Necesito el ver de whatsapp al hacer hover*/
    color: #25D366 !important;
}

.IconsInstagram:hover {
    /* Color gradiente de instagram al hacer hover en este componente*/
    color: #C13584 !important;
}

.IconsFacebook:hover {
    /* Color gradiente de facebook al hacer hover en este componente*/
    color: #3B5998 !important;
}


.PersonalizedHeader a {
    color: #00f0ff;
    text-decoration: none;
}

.PersonalizedHeader a:active{
    color: #ffffff;
    text-decoration: none;
}

.PersonalizedHeader a:hover {
    color: #ffffff;
    text-decoration: none;
}


.PersonalizedActive a{
    color: #ffffff;
    text-decoration: none;
}

