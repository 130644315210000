.mySidebar {
    
    background: #2980B9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}

.logo{
    filter: blur(0px);
    padding: 1rem;
}

.Nombre{
    color:aliceblue;
    
}

.Nombre:hover{
    color: tomato;
    cursor: pointer;
}

.SecondedCont::-webkit-scrollbar {
    display: none;
  }

    .SecondedCont {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }